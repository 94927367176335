
import { defineComponent } from "vue";
import CustomSelect from "@/components/Input/SelectBox.vue";
import _ from "lodash";

export default defineComponent({
  name: "Pincode",
  components: {
    CustomSelect,
  },
  data() {
    return {
      isSecured: true,
      completedStatus: false,
      // companyLogoUrl:
      //   "",
      lang: "en",
      publishSubmitStatus: true,
      isDisabledValidation: true,
      validateFormStatus: false,
      form: {
        email: "",
        fullname: "",
        firstname: "",
        lastname: "",
        acceptTerrms: false,
        sendFreeStuff: false,
      },
      formValidate: {
        email: false,
        fullname: false,
        firstname: false,
        lastname: false,
        acceptTerrms: false,
        sendFreeStuff: false,
      },
      // used for languages required
      isLangEnEnabled: true as boolean,
      isLangThEnabled: false as boolean,
      selectedLanguage: {
        label: "",
        key: "",
      } as any,
    };
  },
  async created() {
    const code = this.$router.currentRoute.value.params.code || "";
    if (!code) {
      this.$router.replace(`/404`);
      return;
    }
    await this.$store.dispatch("routes/getRoute", code, { root: true });
    const routeData = this.$store.getters["routes/data"];
    if (routeData) {
      this.isSecured = routeData.path == "/assessment-test/:id/:params";
      // auto prefill form
      if (this.isSecured) {
        this.form.firstname = routeData.data.firstname;
        this.form.lastname = routeData.data.lastname;
        this.form.email = routeData.data.email;
      } else {
        // no default values
      }

      // @fixed
      this.form.firstname = "Rath";
      this.form.lastname = "Happily";

      // languages required
      if (Array.isArray(routeData.data.languages_required)) {
        this.isLangEnEnabled =
          _.indexOf(routeData.data.languages_required, "English") !== -1;
        this.isLangThEnabled =
          _.indexOf(routeData.data.languages_required, "Thai") !== -1;

        const ref = this.$refs["selectedLanguage"] as any;
        ref.setOptions(this.languages);

        if (routeData.data.languages_required.length == 1) {
          if (this.isLangEnEnabled) {
            this.selectedLanguage = {
              label: "English",
              key: "en",
            };
          }
          if (this.isLangThEnabled) {
            this.selectedLanguage = {
              label: "Thai",
              key: "th",
            };
          }
          ref.selectOption(this.selectedLanguage);
          ref.toggleDropdown();
        }
      }
    } else {
      // redirect or forward to 404 not found
      this.$router.replace(`/404/${code}`);
    }
  },
  computed: {
    languages() {
      const options = [];
      if (this.isLangEnEnabled) {
        options.push({
          label: "English",
          key: "en",
        });
      }

      if (this.isLangThEnabled) {
        options.push({
          label: "Thai",
          key: "th",
        });
      }

      return options;
    },
    companyLogoUrl() {
      const defaultLogoUrl = "";

      let hasTeam = false;
      let isPersonal = true;
      const team = this.$store.getters["team/team"];
      if (team) {
        hasTeam = true;
        isPersonal = team.personal || false;
      }
      const useTeamLogo = hasTeam && !isPersonal;

      const companyLogoUrl = useTeamLogo
        ? this.$store.getters["team/companyLogoUrl"]
        : this.$store.getters["user/companyLogoUrl"];

      return companyLogoUrl || defaultLogoUrl;
    },
    error() {
      return this.$store.getters["auth/error"];
    },
    loading() {
      return this.$store.getters["assessmentTemplate/loading"];
    },
  },
  watch: {
    error(error) {
      if (error) {
        console.error(error);
      }
    },
    lang(value) {
      const setLocale = (lang: string) => {
        (this as any).$i18n.setLocale(lang);
      };
      setLocale(value);
      // console.log(`watch lang(${value})`);
      this.validate();
    },
    form: {
      handler() {
        if (this.isDisabledValidation) {
          this.isDisabledValidation = false;
          return;
        }
        this.validate();
      },
      deep: true,
    },
  },
  methods: {
    handleLanguageInput(newValue: any) {
      this.selectedLanguage = newValue;
      this.lang = newValue.key;
    },
    async __next() {
      this.formValidate.email = true;
      this.formValidate.fullname = true;
      this.formValidate.acceptTerrms = true;
      this.formValidate.sendFreeStuff = true;
      /* eslint-disable */
      const email = this.form.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      /* eslint-enable */
      if (email) {
        this.formValidate.email = false;
      }
      if (this.form.fullname) {
        this.formValidate.fullname = false;
      }
      if (this.form.acceptTerrms) {
        this.formValidate.acceptTerrms = false;
      }
      if (this.form.sendFreeStuff) {
        this.formValidate.sendFreeStuff = false;
      }
      // this.validateFormStatus =
      //   this.formValidate.email == false &&
      //   this.formValidate.fullname == false &&
      //   this.formValidate.acceptTerrms == false &&
      //   this.formValidate.sendFreeStuff == false;
      if (this.validateFormStatus) {
        const valid = await this.$store.dispatch(
          "auth/requestLoginWeb",
          { email: this.form.email },
          { root: true }
        );
        if (valid) {
          this.$router.push("/pincode");
        }
      }
    },
    async next() {
      // @todo You should get data and check here not call previous process
      //       and it's very load data if so many candidates
      this.publishSubmitStatus = false;
      const candidates = this.$store.getters["routes/candidates"];
      if (candidates) {
        candidates.forEach(async (data: any) => {
          if (this.form.email == data.candidateEmail) {
            this.completedStatus = true;
          }
        });
      }
      if (this.completedStatus == false) {
        this.submitEventPublish();
        this.validate(true);
      }
      this.publishSubmitStatus = true;
    },
    async validate(doSubmit = false) {
      this.isSecured
        ? this.validateSecuredForm(doSubmit)
        : await this.validateUnsecuredForm(doSubmit);
      this.publishSubmitStatus = true;
    },
    async validateUnsecuredForm(doSubmit: boolean) {
      this.formValidate.email = true;
      this.formValidate.fullname = true;
      this.formValidate.acceptTerrms = true;
      this.formValidate.sendFreeStuff = true;

      /* eslint-disable */
      const email = this.form.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      /* eslint-enable */
      if (email) {
        this.formValidate.email = false;
      }
      if (this.form.fullname) {
        this.formValidate.fullname = false;
      }
      if (this.form.acceptTerrms) {
        this.formValidate.acceptTerrms = false;
      }
      // if (this.form.sendFreeStuff) {
      //   this.formValidate.sendFreeStuff = false;
      // }
      this.validateFormStatus =
        this.formValidate.email == false &&
        this.formValidate.fullname == false &&
        this.formValidate.acceptTerrms == false &&
        this.lang.length > 0;

      // this.formValidate.sendFreeStuff == false
      if (!doSubmit) return;
      if (this.validateFormStatus) {
        const chunks = this.form.fullname.split(" ");
        this.form.firstname = String(chunks[0]);
        this.form.lastname =
          chunks.length > 1 ? this.form.fullname.split(" ")[1] : " ";
        const candidate = {
          email: this.form.email,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
        };

        // @see invite and createFromInvitation
        // used for public sharing
        // if (!this.form.email) {
        const routeData = this.$store.getters["routes/data"];
        const formDetailsDto = {
          formId: routeData.uuid,
          email: this.form.email,
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          publicUrl: window.location.href,
        };
        await this.$store.dispatch(
          "assessmentTemplate/publicInvite",
          formDetailsDto,
          {
            root: true,
          }
        );
        // don't allow change it
        this.form.email =
          this.$store.getters["assessmentTemplate/publicInvitedEmail"];
        // }

        this.$store.commit("assessment/candidate", candidate);
        this.$emit("finishConfirmCandidate", { ...this.form, lang: this.lang });
      }
    },
    async validateSecuredForm(doSubmit: boolean) {
      this.formValidate.firstname = true;
      this.formValidate.lastname = true;
      this.formValidate.acceptTerrms = true;
      // this.formValidate.sendFreeStuff = true;

      if (this.form.firstname) {
        this.formValidate.firstname = false;
      }
      if (this.form.lastname) {
        this.formValidate.lastname = false;
      }
      if (this.form.acceptTerrms) {
        this.formValidate.acceptTerrms = false;
      }
      // if (this.form.sendFreeStuff) {
      //   this.formValidate.sendFreeStuff = false;
      // }

      this.validateFormStatus =
        this.formValidate.firstname == false &&
        this.formValidate.lastname == false &&
        this.formValidate.acceptTerrms == false &&
        this.lang.length > 0;

      if (!doSubmit) return;

      if (this.validateFormStatus) {
        const candidate = {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
        };

        this.$store.commit("assessment/candidate", candidate);
        this.$emit("finishConfirmCandidate", { ...this.form, lang: this.lang });
      }
    },
    fontColor(status: boolean) {
      if (status) {
        return "color: red;";
      }
      return "color: #1B1C1E;";
    },
    validateStyle(status: boolean) {
      if (status) {
        return "validate-true";
      }
      return "validate-false";
    },
    submitEventPublish() {
      this.publishSubmitStatus = false;
    },
    finishAssessment() {
      if (window.closed) {
        window.close();
      } else {
        window.open("https://myculture.happily.ai/", "_self");
      }
    },
  },
});
